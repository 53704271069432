//@ts-nocheck
import React, { Suspense, useEffect, useState, lazy } from 'react'
import {
  EventReducer,
  ContentSidebar,
  MobileLandscapeOverlay,
  isMobile,
  StripNavigation,
  Alert,
  ThemeProvider,
  styled,
  Form,
  gaInitalise,
  EventName,
  Loading,
} from '@lxlabs/vc-components'
import theme from './config/theme'
import { useUiActions, useUiState } from './reducers/ui'
import menu from './config/menu'
import './assets/styles/babylon.css'
import * as CONTENT from './config/content'
import { MOBILELANDSCAPEOVERLAY_CONTENT, CONTACTFORM_CONTENT, INTRO_CONTENT } from './config/app'
import { ContentTypes } from './config/content'
import BlackFade from './components/BlackFade'
import showWarning from './utils/showWarning'
import menuBg from './assets/images/menu-bg.png'
import logoSymbol from './assets/images/logo-symbol-white-small.png'
import { contentUrl } from './config/api'
import menuBtnBlue from './assets/images/menu-burger-blue.png'
import type2Logo from './assets/images/type-2-logo.png'

const AppScene = lazy(() => import('./AppScene'))

const { useEventActions, useEventState } = EventReducer

process.env.REACT_APP_GTM_ID && gaInitalise({ gtmId: process.env.REACT_APP_GTM_ID })

function App() {
  const [content, setContent] = useState<ContentTypes | ''>('')
  const [showLoader, setShowLoader] = useState(true)
  const [blackFadeClass, setBlackFadeClass] = useState('hidden')

  const [activeTab, setActiveTab] = useState<string>('')
  const uiState = useUiState()
  const uiSetters = useUiActions()
  const eventState = useEventState()
  const eventSetters = useEventActions()

  useEffect(() => {
    const scene = document.querySelector("[id^='aad-scene']")
    if (scene) {
      if (uiState.showPopup && isMobile) {
        scene.style.visibility = "hidden"
      } else {
        scene.style.visibility = "visible"
      }
    }

  }, [uiState.showPopup])

  const onFlyThroughEnd = (cancel = true) => {
    fadeInOut(1200)
    setTimeout(() => {
      if (cancel) {
        eventSetters.setEvent(EventName.CANCEL_FLY_THROUGH)
      }
      eventSetters.setFlyThroughActive(false)
      eventSetters.setFlyThroughViewed(true)
    }, 600)
  }

  const onClose = () => {
    uiSetters.setShowPopup(false)
    fadeInOut(1200)
    setTimeout(() => {
      eventSetters.setEvent(EventName.JUMP_TO_START)
    }, 600)
  }

  const showFade = () => {
    setBlackFadeClass('visible')
  }

  const hideFade = () => {
    setBlackFadeClass('click-through')
  }

  const fadeInOut = (time: number) => {
    showFade()
    setTimeout(() => {
      hideFade()
    }, time)
  }

  const onNavigationSelect = (instance: string) => {
    if (instance === 'FORM') {
      setActiveTab(instance)
    } else {
      uiSetters.setShowForm(false)
      if (instance === 'default') {
        onClose()
      } else {
        uiSetters.setShowPopup(false)
        uiSetters.setAction('')
        fadeInOut(1200)

        setTimeout(() => {
          eventSetters.setEvent(EventName.JUMP_TO_OBJECT, instance)
        }, 600)
      }
    }
  }

  return (
    <>
      <ThemeProvider theme={theme({ active: activeTab })}>
        {isMobile && (
          <MobileLandscapeOverlay
            content={MOBILELANDSCAPEOVERLAY_CONTENT.overlayMessage}
            mobileLandscapeImage={`${contentUrl}/images/mobile-landscape-overlay.svg`}
          />
        )}
        {isMobile && uiState.isReady && (
          <StripNavigation
            skewDegrees={'0deg'}
            navItems={menu.menuItems}
            onSelect={onNavigationSelect}
            logo={type2Logo}
            logoWidth={'220px'}
            menuBtn={menuBtnBlue}
            active={activeTab ? activeTab : 'default'}
            showForm={uiState.showForm}
            setShowForm={uiSetters.setShowForm}
          />
        )}
        {uiState.showPopup && (
          <>
            <ContentSidebar contents={CONTENT[content as ContentTypes]} onClose={onClose} />
          </>
        )}
        {eventState.flyThroughActive && <SkipFlyThrough onClick={onFlyThroughEnd}>SKIP</SkipFlyThrough>}

        <BlackFade
          onClick={() => {
            if (eventState.flyThroughActive) {
              onFlyThroughEnd()
            }
          }}
          className={`${blackFadeClass}`}
        />
        <Form
          url={process.env.REACT_APP_CONTACT_US_ENDPOINT}
          content={CONTACTFORM_CONTENT}
          showForm={uiState.showForm}
          setShowForm={uiSetters.setShowForm}
        />
        <Suspense fallback={<></>}>
          <AppScene
            eventState={eventState}
            eventSetters={eventSetters}
            setActiveTab={setActiveTab}
            setContent={setContent}
            uiState={uiState}
            uiSetters={uiSetters}
            hideFade={hideFade}
            onFlyThroughEnd={onFlyThroughEnd}
            showLoader={showLoader}
          />
        </Suspense>
        {!eventState.flyThroughActive && !isMobile && uiState.isReady && (
          <StripNavigation
            skewDegrees={'0deg'}
            navItems={menu.menuItems}
            onSelect={onNavigationSelect}
            active={activeTab ? activeTab : 'default'}
            multipleLines={true}
            showForm={uiState.showForm}
            setShowForm={uiSetters.setShowForm}
          />
        )}
        {showLoader && <Loading isReady={uiState.isReady} content={INTRO_CONTENT} setShowLoader={setShowLoader} />}
        <Alert
          message="For an optimal experience, please switch your browser to Google Chrome."
          closeText="OK"
          show={showWarning()}
          menuBg={menuBg}
          logoSymbol={logoSymbol}
        />
      </ThemeProvider>
    </>
  )
}

const SkipFlyThrough = styled.p`
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 100;
  :hover {
    color: #f9f9f9;
    cursor: pointer;
    font-size: 1.3rem;
  }
`

export default App
