import { contentUrl, screensUrl } from '../api'

export const IMMUNE_RESPONSE = {
  name: 'IMMUNE_RESPONSE Screen',
  title: 'IMMUNE_RESPONSE',
  mobileType: "mobile",
  overlay: {
    type: "ClickableImage",
    options: {
      src: `${screensUrl}/Screen1.png?cacheblock=true`,
      srcMobile: `${screensUrl}/Screen1.png?cacheblock=true`,
    },
  },
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'INTRODUCTION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-1/inflammation-in-dermatology_desktop@2x.png?cacheblock=true`, alt: "Immune Response - Inflammation in Dermatology" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-1/inflammation-in-dermatology_desktop@2x.png?cacheblock=true`, alt: "Immune Response - Inflammation in Dermatology" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'TYPE OF IMMUNE RESPONSE',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-1/immune-response_desktop@2x.png?cacheblock=true`, alt: "Immune Response" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-1/immune-response_desktop@2x.png?cacheblock=true`, alt: "Immune Response" }],
        },
      }
    },
  ],
}
