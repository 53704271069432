import { contentUrl, screensUrl } from '../api'

export const SKIN_DISEASES = {
  name: 'SKIN_DISEASES Screen',
  title: 'SKIN_DISEASES',
  mobileType: "mobile",
  overlay: {
    type: "ClickableImage",
    options: {
      src: `${screensUrl}/Screen2.png?cacheblock=true`,
      srcMobile: `${screensUrl}/Screen2.png?cacheblock=true`,
    },
  },
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'OVERVIEW',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-2/beyond-AD_desktop@2x.png?cacheblock=true`, alt: "Skin - Beyond Atopic Dermatitis" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-2/beyond-AD_desktop@2x.png?cacheblock=true`, alt: "Skin - Beyond Atopic Dermatitis" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'AD',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-2/AD.gif`, alt: "Skin - Atopic Dermatitis" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-2/AD.gif`, alt: "Skin - Atopic Dermatitis" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'PN',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-2/prurigo-nodularis_desktop@2x.png?cacheblock=true`, alt: "Skin - Prurigo Nodularis" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-2/prurigo-nodularis_desktop@2x.png?cacheblock=true`, alt: "Skin - Prurigo Nodularis" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'CSU',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-2/chronic-spontaneous-urticaria_desktop@2x.png?cacheblock=true`, alt: "Skin - Chronic Spontaneous Urticaria" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-2/chronic-spontaneous-urticaria_desktop@2x.png?cacheblock=true`, alt: "Skin - Chronic Spontaneous Urticaria" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'BP',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-2/bullous-pemphigoid_desktop@2x.png?cacheblock=true`, alt: "Skin - Bullous-Pemphigoid" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-2/bullous-pemphigoid_desktop@2x.png?cacheblock=true`, alt: "Skin - Bullous-Pemphigoid" }],
        },
      }
    },
  ],
}
