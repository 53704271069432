export const CONTACTFORM_CONTENT = {
  hasMessageBox: true,
  titleHeader: "Let's continue the conversation",
  titleCopy: "To stay in contact with us and learn more about our congress activities, please fill out the information below",
  privacyPolicyTitle: "INFORMATION & CONSENT FOR THE PROCESSING OF PERSONAL DATA",
  privacyPolicyCopy1: "For the purposes of providing you the services offered by this website, you will be requested to communicate personal information. The processing of this information is necessary to allow us to communicate with you regarding information or resources that you request, and/or to implement pharmacovigilance processing. We may share some of your personal information, the information relating to your use of this website and your interactions with the website to one or more affiliated companies or to our Alliance partner, Regeneron Pharmaceuticals Inc.",
  privacyPolicyCopy2: "This information may be processed outside your jurisdiction (including where your jurisdiction is in the European Union) in countries which do not provide the same level of protection to personal data. The data we receive will generally be retained for the duration of your contractual relationship with Sanofi and in case only for the duration that is necessary to fulfil the above mentioned purposes, unless further retention is necessary to meet legal or regularity requirements or for the protection of Sanofi’s interests. The principles set out in our Privacy Policy shall apply to our processing of this information.",
  legalAgreementCopy: "By clicking the submit button below, you confirm that you have read and accept our <a href='https://www.sanofi.us/en/legal-notice/#Privacypolicy' target='_blank' style='text-decoration: underline'>Terms of Use and Privacy Policy</a>.",
  legalAgreementCheckbox: "I understand and agree",
  buttonCopy: "SUBMIT"
}
