import { contentUrl } from '../api'

export const MECHANISM_OF_DISEASE = {
  name: 'ATOPIC_DERMATITIS_IN_DETAIL Screen',
  title: 'MECHANISM_OF_DISEASE',
  mobileType: "mobile",
  content: [
    {
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${contentUrl}/hub-4/MOD.mp4`,
          poster: `${contentUrl}/hub-4/mod-poster.png?cacheblock=true`,
        },
      },
    },
  ]
}
