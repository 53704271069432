import stripNavBgImage from '../assets/images/strip-nav-bg.png'
import bottomBgImage from '../assets/images/bg-bottom.png'

const variables = {
    colors: {
        primary: "#2480C1",
        primaryHover: "#660099",
        primaryDark: "#122668",
        secondary: "#273D98",
        secondaryHover: "#FC3D55",
        secondaryDark: "#FF8D22",
        tertiary: "#FA3D8C", 
        tertiaryHover: "", 
        tertiaryDark: "", 
        white: "#FFF",
        black: "#000",
        lightgrey: "#F5F5F5",
        grey: "#DBD9D9",
        darkgrey: "#796E65",
    },
}

const loadingScreen = {
    loadingScreenBorderBottom: variables.colors.primary
}

const menu = {
    menuTitleBg: variables.colors.white,
    menuBgPrimary: variables.colors.primary,
    menuBgPrimaryActive: variables.colors.primary,
    menuBgPrimaryHover: variables.colors.primaryHover,
    menuBgSecondary: variables.colors.lightgrey,
    menuBgSecondaryActive: variables.colors.white,
    menuBgSecondaryHover: variables.colors.grey,
    menuBgTertiary: variables.colors.white,
    menuBgTertiaryActive: "",
    menuBgTertiaryHover: "",
    
    menuTabActive: variables.colors.secondary,
    menuTabInactive: variables.colors.primary,

    menuTextPrimary: variables.colors.primary,
    menuTextSecondary: variables.colors.darkgrey,
    menuTextTertiary: variables.colors.white,
}

const navigation = {
    navBgPrimary: variables.colors.primary,
    navBgPrimaryActive: variables.colors.primary,
    navBgPrimaryHover: variables.colors.primaryHover,
    navBgPrimaryDark: variables.colors.primaryDark,
    navBgSecondary: variables.colors.grey,
    navBgSecondaryActive: variables.colors.white,
    navBgSecondaryHover: variables.colors.lightgrey,
    navBgTertiary: variables.colors.secondary,
    navBgTertiaryActive: variables.colors.secondaryHover,
    navBgTertiaryHover:  variables.colors.secondaryHover,

    navTabActive: variables.colors.secondary,
    navTabInactive: variables.colors.primary,

    navTextPrimary: variables.colors.primary,
    navTextSecondary: variables.colors.white,
    navTextTertiary: variables.colors.darkgrey,

    navBottomBgImage: bottomBgImage,
}

const IMMUNE_RESPONSE = {
    menuBgPrimary: variables.colors.secondary,
    menuBgPrimaryActive: variables.colors.white,
    menuBgPrimaryHover: variables.colors.white,
    
    menuTabActive: variables.colors.white,
    menuTabInactive: variables.colors.secondary,

    menuTextPrimary: variables.colors.secondary,
    menuTextSecondary: variables.colors.secondary,

    navBgPrimary: variables.colors.secondary,
    navTabActive: variables.colors.secondary,
}

const SKIN_DISEASES = {
    menuBgPrimary: variables.colors.secondaryHover,
    menuBgPrimaryActive: variables.colors.white,
    menuBgPrimaryHover: variables.colors.white,
    
    menuTabActive: variables.colors.white,
    menuTabInactive: variables.colors.secondaryHover,

    menuTextPrimary: variables.colors.secondaryHover,
    menuTextSecondary: variables.colors.secondaryHover,

    navBgPrimary: variables.colors.secondaryHover,
    navTabActive: variables.colors.secondaryHover,
}

const MARBLE_RUN = {
    menuBgPrimary: variables.colors.primary,
    menuBgPrimaryActive: variables.colors.white,
    menuBgPrimaryHover: variables.colors.white,
    
    menuTabActive: variables.colors.white,
    menuTabInactive: variables.colors.primary,

    menuTextPrimary: variables.colors.primary,
    menuTextSecondary: variables.colors.primary,

    navBgPrimary: variables.colors.primary,
    navTabActive: variables.colors.primary,
}

const MECHANISM_OF_DISEASE = {
    menuBgPrimary: variables.colors.secondaryHover,
    menuBgPrimaryActive: variables.colors.white,
    menuBgPrimaryHover: variables.colors.white,
    
    menuTabActive: variables.colors.white,
    menuTabInactive: variables.colors.secondaryHover,

    menuTextPrimary: variables.colors.secondaryHover,
    menuTextSecondary: variables.colors.secondaryHover,

    navBgPrimary: variables.colors.secondaryHover,
    navTabActive: variables.colors.secondaryHover,
}

const OTHER_TYPE_TWO = {
    menuBgPrimary: variables.colors.secondaryDark,
    menuBgPrimaryActive: variables.colors.white,
    menuBgPrimaryHover: variables.colors.white,
    
    menuTabActive: variables.colors.white,
    menuTabInactive: variables.colors.secondaryDark,

    menuTextPrimary: variables.colors.secondaryDark,
    menuTextSecondary: variables.colors.secondaryDark,

    navBgPrimary: variables.colors.secondaryDark,
    navTabActive: variables.colors.secondaryDark,
}

const buttons = {
    buttonBgPrimary: variables.colors.primary,
    buttonBgSecondary: variables.colors.white,

    buttonBorderPrimary: variables.colors.primary,
    buttonBorderSecondary: variables.colors.white,

    buttonTextPrimary: variables.colors.primary,
    buttonTextSecondary: variables.colors.white,
}

const alert = {
    alertBgPrimary: variables.colors.primary,
    alertBgSecondary: variables.colors.secondary,
    alertBgTertiary: variables.colors.grey,
    alertBgTertiaryHover: variables.colors.white,

    alertBorderPrimary: variables.colors.primary,
    alertBorderSecondary: variables.colors.secondary,

    alertTextPrimary: variables.colors.primary,
    alertTextSecondary: variables.colors.secondary,
}

const stripNav = {
    stripNavBgPrimary: variables.colors.primary,
    stripNavBgSecondary: variables.colors.darkgrey,
    stripNavBgActive: variables.colors.white,
    stripNavBgBorder: variables.colors.secondaryDark,

    stripNavMobileBgPrimary: variables.colors.lightgrey,
    stripNavMobileBgActive: variables.colors.primary,
    stripNavMobileBgBorder: variables.colors.primary,

    stripNavMobileBgLinks: variables.colors.grey,
    stripNavMobileLinksText: variables.colors.darkgrey,

    stripNavMobileFont: "'DIN', 'Gotham', sans-serif, Arial",
    stripNavBgImage: stripNavBgImage,

    stripNavHeight: "60px"
}

const scrollbar = {
    scrollbarBgGradient: "#2480C1, #2480C1, #273D98",
    scrollbarThumb: "#CDE6F6",
}

const customMenuTheme = {
    IMMUNE_RESPONSE,
    SKIN_DISEASES,
    MARBLE_RUN,
    MECHANISM_OF_DISEASE,
    OTHER_TYPE_TWO
}

const customNavTheme = {
    IMMUNE_RESPONSE,
    SKIN_DISEASES,
    MARBLE_RUN,
    MECHANISM_OF_DISEASE,
    OTHER_TYPE_TWO
}

const theme = ({ active }) => {
    return {
        loadingScreen,
        menu: {
            ...menu,
            ...customMenuTheme[active] ? customMenuTheme[active] : {}
        },
        navigation: {
            ...navigation,
            ...customNavTheme[active] ? customNavTheme[active] : {}
        },
        buttons,
        alert,
        stripNav,
        scrollbar
    }
  }

export default theme