import { contentUrl } from '../api'

export const MARBLE_RUN = {
  name: 'EXPLORE_THE_INFLAMMATORY_CASCADE Screen',
  title: 'MARBLE_RUN',
  mobileType: "mobile",
  content: [
    {
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          src: `${contentUrl}/hub-3/marble-run-video.mp4`,
          poster: `${contentUrl}/hub-3/marble-run-poster.png?cacheblock=true`,
        },
      },
    },
  ]
}
