const menu = {
    loaded: true,
    menuItems: [
      {
        instance: 'default',
        title: 'HOME',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'IMMUNE_RESPONSE',
        title: 'IMMUNE RESPONSES',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'Explore Dupixent Data',
        text: '',
        video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandData.mp4',
        loopTime: 5000,
        autoPlay: true,
        playVideoInSitu: false,
        offset: 7,
        selectable: true,
        content: {
          name: 'ClinicalData',
          text: 'Watch the journeys of Real Dupixent Patients',
          config: 'ClinicalProgramsConfig',
        },
        subHeading: [
          { name: 'clinical program', items: ['trial program', 'atopic dermatitis trials'] },
          { name: 'efficacy', items: ['itch reduction', 'skin clearance'] },
          { name: 'safety profile', items: ['adverse events & long-term safety profile', 'discontinuation rates'] },
          { name: 'other attributes', items: [] },
          {
            name: 'real-world data',
            items: ['real-world itch', ' real-world skin clearance', 'real-world persistence'],
          },
        ],
      },
      {
        instance: 'SKIN_DISEASES',
        title: 'SKIN DISEASES',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'Study Designs',
        selectable: true,
        content: {
          name: 'StudyDesigns',
          text: 'Study Designs',
          config: 'StudyDesignConfig',
        },
      },
      {
        instance: 'MARBLE_RUN',
        title: 'EXPLORE THE INFLAMMATORY CASCADE',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'WATCH THE JOURNEYS OF REAL DUPIXENT PATIENTS',
        text: '',
        video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandPatientStories.mp4',
        loopTime: 7000,
        autoPlay: true,
        playVideoInSitu: false,
        offset: 5,
        selectable: true,
        content: {
          name: 'PatientStories',
          text: 'Watch the journeys of Real Patient Stories',
          config: 'PatientSuccessStoriesConfig',
        },
        subHeading: [{ name: 'annie' }, { name: 'kristy' }, { name: 'rachel' }, { name: 'britton' }],
      }, 
      {
        instance: 'MECHANISM_OF_DISEASE',
        title: 'ATOPIC DERMATITIS IN DETAIL',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'Uncover Visible Results',
        selectable: true,
        offset: 5,
        content: {
          name: 'VisibleResults',
          text: 'Uncover Real Visible Results',
          config: 'VisibleResultsConfig',
        },
        subHeading: [
          { name: 'adults', items: [] },
          { name: 'adolescents', items: [] },
          { name: 'children', items: [] },
          { name: 'study design' },
        ],
      },
      {
        instance: 'OTHER_TYPE_TWO',
        title: 'BEYOND THE SKIN',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'WATCH THE JOURNEYS OF REAL DUPIXENT PATIENTS',
        text: '',
        video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandPatientStories.mp4',
        loopTime: 7000,
        autoPlay: true,
        playVideoInSitu: false,
        offset: 5,
        selectable: true,
        content: {
          name: 'PatientStories',
          text: 'Watch the journeys of Real Patient Stories',
          config: 'PatientSuccessStoriesConfig',
        },
        subHeading: [{ name: 'annie' }, { name: 'kristy' }, { name: 'rachel' }, { name: 'britton' }],
      },     
      {
        contactUsTitle: "CONTACT US",
        color: "stripNavBgPrimary",
        mobileColor: 'stripNavMobileBgPrimary',
        linkTitleUrl: 'https://www.sanofi.com/en/contact',
        showInDropdown: true,
        openForm: false,
        target: "_target",      
      }
    ],
  };

export default menu;