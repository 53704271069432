export * from './SKIN_DISEASES'
export * from './MECHANISM_OF_DISEASE'
export * from './IMMUNE_RESPONSE'
export * from './OTHER_TYPE_TWO'
export * from './MARBLE_RUN'

export enum ContentTypes {
  SKIN_DISEASES = 'SKIN_DISEASES',
  MECHANISM_OF_DISEASE = 'MECHANISM_OF_DISEASE',
  IMMUNE_RESPONSE = 'IMMUNE_RESPONSE',
  OTHER_TYPE_TWO = 'OTHER_TYPE_TWO',
  MARBLE_RUN = 'MARBLE_RUN',
}

export const contentNavigation = [
  { title: 'SKIN_DISEASES', color: "stripNavBgPrimary", instance: ContentTypes.SKIN_DISEASES },
  { title: 'MECHANISM_OF_DISEASE', color: "stripNavBgPrimary", instance: ContentTypes.MECHANISM_OF_DISEASE },
  { title: 'IMMUNE_RESPONSE', color: "stripNavBgPrimary", instance: ContentTypes.IMMUNE_RESPONSE },
  { title: 'OTHER_TYPE_TWO', color: "stripNavBgPrimary", instance: ContentTypes.OTHER_TYPE_TWO },
  { title: 'MARBLE_RUN', color: "stripNavBgPrimary", instance: ContentTypes.MARBLE_RUN }
]
