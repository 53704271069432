import { contentUrl, screensUrl } from '../api'

export const OTHER_TYPE_TWO = {
  name: 'BEYOND_THE_SKIN Screen',
  title: 'OTHER_TYPE_TWO',
  mobileType: "mobile",
  overlay: {
    type: "ClickableImage",
    options: {
      src: `${screensUrl}/Screen4.png?cacheblock=true`,
      srcMobile: `${screensUrl}/Screen4.png?cacheblock=true`,
    },
  },
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'OVERVIEW',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-5/other-type-2-inflammatory-diseases_desktop@2x.png?cacheblock=true`, alt: "Other Type 2 overview" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-5/other-type-2-inflammatory-diseases_desktop@2x.png?cacheblock=true`, alt: "Other Type 2 overview" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'ASTHMA',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-5/asthma_desktop@2x.png?cacheblock=true`, alt: "Asthma" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-5/asthma_desktop@2x.png?cacheblock=true`, alt: "Asthma" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'CRSwNP',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-5/crsnp_desktop@2x.png?cacheblock=true`, alt: "CRsNP" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-5/crsnp_desktop@2x.png?cacheblock=true`, alt: "CRsNP" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'EoE',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/hub-5/eoe_desktop@2x.png?cacheblock=true`, alt: "EoE" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/hub-5/eoe_desktop@2x.png?cacheblock=true`, alt: "EoE" }],
        },
      }
    },
  ],
}
