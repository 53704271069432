import React from 'react'
import ReactDOM from 'react-dom'
import { EventReducer } from '@lxlabs/vc-components'
import reportWebVitals from './reportWebVitals'
import { UiProvider, } from './reducers/ui'
import App from './App'
import './assets/styles/base.css'
import './assets/styles/fonts.css'

const { EventProvider } = EventReducer

ReactDOM.render(
  <React.StrictMode>
    <UiProvider>
      <EventProvider>
          <App />
      </EventProvider>
    </UiProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
